import React from "react";
import "./login.css";
import { ReactComponent as Logo } from "../media/logo.svg";
import { ReactComponent as LogoGoogle } from "../media/google.svg";
import { ReactComponent as LogoDiscord } from "../media/discord.svg";

function Login() {
	return (
		<div className="login-inner">
			<div className="login-svg-container">
				<Logo className="login-svg" />
			</div>
			<h1 className="login-heading">Welcome to The Coven!</h1>
			<div className="login-quote">
				<p>
					A sanctuary for witches and like-minded souls to share, learn, and
					weave their own enchanting destinies.
				</p>
			</div>

			<div className="login-button google">
				<LogoGoogle className="login-svg-sign-in" />
				Sign in with Google
				<div></div>
			</div>

			<div className="login-button discord">
				<LogoDiscord className="login-svg-sign-in" />
				<div>Sign in with Discord</div>
				<div></div>
			</div>

			<div className="login-divider-container">
				<div className="login-divider"></div>
				<div className="login-divider-text">or</div>
				<div className="login-divider"></div>
			</div>

			<div className="login-button email">
				<div>Sign in with Email</div>
			</div>
		</div>
	);
}

export default Login;
