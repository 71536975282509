
import { isMobile } from "react-device-detect";
import "./App.css";
import Login from "./components/Login";
import Video from "./components/Video";
import { useEffect, useState } from "react";

function App() {
  const [isScreenSmall, setIsScreenSmall] = useState(window.matchMedia('(max-width: 560px)').matches);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.matchMedia('(max-width: 560px)').matches);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

	return (
		<div className="App">
      {!isMobile && !isScreenSmall &&(<div className="video-container">
				<Video />
			</div>)}
			
			<div className={`login-container ${isMobile || isScreenSmall ? "mobile" : "desktop"}`}>
        <Login/>
      </div>
		</div>
	);
}

export default App;
