import React, { useEffect, useRef, useState } from "react";
import "./video.css";
import video from "../media/coven.mp4";
import PlayIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

function Video() {
	const [isPlaying, setIsPlaying] = useState(true);
	const [zoom, setZoom] = useState(0);
	const videoRef = useRef();

	const handleTogglePlay = () => {
		setIsPlaying((prevIsPlaying) => !prevIsPlaying);

		if (isPlaying) {
			videoRef.current.pause();
		} else {
			videoRef.current.play();
		}
	};

	useEffect(() => {
    videoRef.current.play();
    
		const handleZoom = () => {
			let zoomLevel = window.devicePixelRatio;

			if (zoomLevel < 1) {
				setZoom(1);
			} else {
				setZoom(2);
			}

			console.log("zoomLevel:", zoomLevel);
		};

		handleZoom();

		window.addEventListener("resize", handleZoom);

		return () => {
			window.removeEventListener("resize", handleZoom);
		};
	}, []);

	return (
		<div className="video-inner">
			<video
				ref={videoRef}
				loop
        muted
				autoPlay={isPlaying}
				style={{ transform: zoom === 1 ? "scale(5)" : "scale(1)" }}
			>
				<source src={video} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<div
				className="video-controls"
				style={{ justifyContent: isPlaying ? "flex-start" : "flex-end" }}
				onClick={handleTogglePlay}
			>
				<div className="video-controls-circle">
					{isPlaying ? (
						<PauseIcon sx={{ color: "#CA4C3B" }} />
					) : (
						<PlayIcon sx={{ color: "#CA4C3B" }} />
					)}
				</div>
			</div>
		</div>
	);
}

export default Video;
